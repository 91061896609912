.nav-header{
    padding-left: 12px;
}

.nav-text{
font-size: 18px;
color: #474343
}
.nav-text:hover {
    color: #ecbb9c !important;
}
.nav-elements{
    margin-left: 28px;
}