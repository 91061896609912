.box1-container {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .sec-1{
    width: 50%;
  }
  .sec2{
    width: 50%;
    position: relative;
    min-height: 100% !important;
  }
  .sec1-content{
    margin-bottom: 20px;
    padding-right: 100px;
    padding-bottom: 6%;
    padding-top: 22px;
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #252525;
    color: #fff
  }
  .sec1-header{
    font-size:4em;
    font-weight:300;
    letter-spacing:0.1em;
    margin-top:10px;
    text-align:start
  }
  .sec1-text-wrapper{
    padding-left:9px;
    margin-top:10px;
    border-left:4px solid #1e4e90
  }
  #bgvideo{
    min-width: 100%; 
    min-height: 100%;
    height:auto;
  }
  .video-item{
    position:absolute;
    min-height: 100% !important;
    height:100%;
    width:100%;
    max-width: 50vw !important;
    overflow: hidden;
    margin-top: -20px;
  }
  @media only screen and (max-width: 1120px) {
    .box1-container{
      min-width: 100% !important;
    }
    .sec-1{
      min-width: 100% !important;
    }
    .sec2{
      min-width: 100% !important;
      min-height: 400px !important;
    }
    .video-item{
      min-width: 100% !important;
      min-height: 400px !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .sec1-content{
      padding-right: 20% !important;
    }
    .sec1-header{
      font-size: 3em !important;
    }
  }
  @media only screen and (max-width: 500px) {
    .sec1-content{
      padding-right: 1.5em !important;
    }
    .sec1-header{
      font-size: 2.5em !important;
    }
  }
  .btn-explore{
    border: 1px solid #1b1a1a;
    background-color: #335f9a;
    padding: 12px 48px;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
    margin-top: 20px;
  }

  .btn-explore:hover{
    color:#1b1a1a;
    border: 1px solid#fff;
    background-color: #fff;
  }
  .box2-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: 5rem;
    
  }
  .sec-3{
    width: 50%;
  }
  .sec3-image{
    width: 400px;
  }
  .sec-4{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: 15%;
    text-align: start;
  }

  @media only screen and (max-width: 1120px) {
    .box2-container{
      min-width: 100% !important;
      flex-direction: column;
      justify-content: center;
    }
    .sec-3{
      width: 100% !important;
    }
    .sec-4{
      width: 100% !important;
      padding-left: 10%;
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 560px) {
    .box2-container{
      margin-top: 2em !important;
    }
    .sec-4{
      min-width: 100% !important;
      padding-left: 10%;
      margin-top: 20px;
      padding-right: 10px !important;
    }
    .sec3-image{
      width: 300px;
    }
  }
  .sec-5{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3.5rem;
  }
  .btn-see-all:hover{
    border-style: solid;
    border-color: #000;
    background-color:#335f9a;
    color: #fff;
  }
  .btn-see-all{
    border: 0.5px solid #1b1a1a;
    background-color:  #fff;
    padding: 14px 36px;
    border-radius: 25px;
    color: #1b1a1a;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
  }
  .image-container{
    display: flex;
    align-items: flex-start;

  }
  .devise-wrapper{
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 3.5rem;              
  }
  .ddd-card{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    margin-right: 6rem;
  }
  .ddd-card-header{
    margin-top: 1.2rem;
    font-weight: lighter;
  }

  .ddd-card-description{
    font-size: 1.1em;
    font-weight: 300;
    margin-top: 10px
  }

  
  @media only screen and (max-width: 1120px) {
    .devise-wrapper{
      flex-direction: column;
      align-items: flex-start;
    }
    .btn-see-all{
      margin-left: 0rem !important;
      margin-top: 16px;
    }
  }
  @media only screen and (max-width: 900px) {
    .devise-wrapper{
      margin-bottom: 1rem;
    }
    .image-container{
      flex-direction: column;
    }
    .ddd-card{
      width: 100%;
      margin-top: 2rem;
    }
  }
  .sec-5{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3.5rem;
  }
  .btn-see-all:hover{
    border-style: solid;
    border-color: #000;
    background-color:#335f9a;
    color: #fff;
  }

  .box4-container{
    display: flex;
    background-color: #252525;
    color: #fff;
    flex-direction: column;
    height: 44.2em;
  }
  .competencies-card-wrapper{
    display: flex;
    flex-wrap:wrap;
    width: 100%;
    height: 100%;
    margin-top: 1.5em;
  }
  .competencies-card{
    width:25%;
    position: relative;
    height: 30em;
    
  }
  .competencies-header{
    font-weight:700;
    padding-right:30%;
  }

  .competencies-image{
    width:100%;
    height:30em;
    -webkit-mask: linear-gradient(#000, #0000);
    mask: linear-gradient(#000, #0000)
  }

  @media only screen and (max-width: 1000px) {
    .box4-container{
      height: 100% !important;
    }
    .competencies-card{
      width: 50%;
    }
    .competencies-header{
      margin-bottom: 5em;
      padding-left: 1em;
      font-weight: 800;
      letter-spacing: 0.1em;
    }
    .competencies-text{
      padding-right: 2.5em;
      padding-left: 1em;
    }
  }
  @media only screen and (max-width: 765px) {
    .competencies-card{
      width: 100%;
    }
    .competencies-header{
      font-size: 1.4rem;

    }
    .competencies-text{
      font-size: 1.0rem;
      padding-right: 2em;
    }
    #corss-border-img{
      height: 32em !important;
    }
  #corss-border-wrapper{
      height: 32em !important;
    }online-store
    #online-store-img{
      height: 34em !important;
    }
  #online-store-wrapper{
      height: 34em !important;
    }
  }
  @media only screen and (max-width: 500px) {
    .competencies-header{
      font-size: 1.4rem;
      padding-right: 1em !important;
      margin-bottom: 3em !important;
    }
  }
  .text {
    position: absolute;
    top: 3em;
    left: 1.5em;
    text-align: start;
  }
  .empty-box{
    height: 6em;
    background-color: #252525;
  }
  @media only screen and (max-width: 500px) {
    .empty-box{
      height: 3em;
    }
  }
  .box5-container{
    display: flex;
    flex-wrap: wrap;
  
  }
  .business-card{
    display: flex;
    background-color: #252525;
    color: #fff;
    flex-direction: column;
    width: 50%;
    padding-left: 1em;
    align-items: flex-start;
    
  }
  .business-card1{
    display: flex;
    flex-direction: row;
  }
  .image-card{
    width: 50%;
   display: flex;
  }
.business-header{
  font-size:2.6em;
  font-weight:100;
  letter-spacing:0.1em;
  margin-top:10px;
  color:#fff;
  padding-right:4em;
  text-align:start
}
  @media only screen and (max-width: 1000px) {
    .image-card{
      width: 100%;
    }
    .business-card{
      width: 100%;
      padding-left: 2em;
      padding-bottom: 1em;
    }
    .business-header{
      padding-right: 2em;
      margin-top: 1em;
    }
    .business-card1{
      margin-top: 1em;
    }
    .dre-card{
      margin-top: 2em !important;
    }
  }
  @media only screen and (max-width: 765px) {
    .dre-card{
      width: 100% !important;
      margin-top: 2em !important;
    }
    .dre-text{
      line-height: 1.55rem !important;
    }
    .business-card1{
      margin-top: 1em;
      flex-wrap: wrap;
    }
  }

  .btn-service:hover{
    color:#1b1a1a;
    border: 1px solid#fff;
    background-color: #fff;

  }
  .btn-service{
    border: 1px solid #1f5191;
    background-color: #335f9a;
    padding: 14px 48px;
    border-radius: 30px;
    color: #fff;
    font-size: 1.3rem;
    line-height: 1.3rem;
    font-weight: 400;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
    margin-left: 1em;
    margin-top: 1em;

  }
  .text-business-container{
    align-items:center;
    display: flex;
    justify-content: flex-start;
    margin-top: 1em;

  }
  .dre-card{
    width: 33%;
    text-align: start;
    margin-left: 1em;
    padding-right: 1em;
   
  }
  .dre-text{
    font-size: 1.4rem;
    font-weight: 100;
    line-height: 1.7rem;
  }
  .dre-header{
    font-weight: 500;
    letter-spacing: 0.1rem;
  }
  
  .brand-header{
    text-align:center;
    margin-top:3em; 
    font-size:2.1rem;
  }
  .brand-image-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 4em;
    margin-bottom: 5em;
  }
  .brand-image{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    margin: 1em;
  }
  @media only screen and (max-width: 1000px) {
    .brand-header{
      margin-top: 2em !important;
    }

    .brand-image-container{
      padding-top: 3em !important;
      margin-bottom: 3em !important;
    }
  }
  @media only screen and (max-width: 765px) {
    .brand-header{
      margin-top: 1.5em !important;
    }

    .brand-image-container{
      padding-top: 1.5em !important;
      margin-bottom: 3em !important;
    }
  }

.news-container{
  background-color:  #252525;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3.4em;
  padding-right: 3.4em;
  padding-top: 1.5em;
  
}
.news-image{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5em;
  justify-content: space-evenly;
  
}
.news-img{
  height: 16.5em;
  width: 21.5em;
}
.news-card{
  width:33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start ;
  padding: 0em 0.6em;
  }

  .news-header{
    font-weight:lighter;
    font-size:2.9rem;
    margin-top:0.4em;
    padding-right:75%;
    text-align:start
  }
  @media only screen and (max-width: 1250px) {
    .news-container{
      padding-right: 1.5em !important;
      padding-left: 1.5em !important;
    }
  }
  @media only screen and (max-width: 1150px) {
    .news-img{
      width: 18em !important;
      height: 14em !important;
    }
  }
  @media only screen and (max-width: 1060px) {
    .news-img{
      width: 15em !important;
      height: 11.5em !important;
    }
    .news-header{
      padding-right: 60% !important;
    }
  }
  @media only screen and (max-width: 850px) {
    .news-img{
      width: 12em !important;
      height: 9em !important;
    }
  }
  @media only screen and (max-width: 700px) {
    .news-card{
      width: 100%;
      margin-top: 2em;
    }
    .news-img{
      height: 16.5em !important;
      width: 20em !important;
    }
    .news-header{
      padding-right: 25% !important;
    }
    .news-container{
      padding-left: 2em !important;
    }
  }
  @media only screen and (max-width: 410px) {
    .news-img{
      height: 16em !important;
      width: 18em !important;
    }
    .news-image{
      margin-top: 0.5em !important;
    }
    .news-header{
      padding-right: 10% !important;
      font-size: 2rem !important;
    }
  }
  .news-btn-see-all{
    border: 0.5px solid #fff;
    background-color:  #252525;
    padding: 14px 36px;
    border-radius: 25px;
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
   margin-top: 1.5em;
  }
  .news-btn-see-all:hover{
    border-style: solid;
    border-color: #000;
    background-color: #fff;
    color:  #252525;
  }
  .about-image{
    width:50%; 
    height: 38em;
  }
  .about-container{
    display: flex;
    flex-direction: row;
    background-color: #252525;
    padding-top: 5em;
    
  }
  .about-text{
    color:#fff;
    font-size:4rem;
    font-weight:100;
    text-align:start;
    padding-right:2em;
    line-height:4.2rem
  }
  @media only screen and (max-width: 1150px) {
    .about-text{
      font-size: 3rem !important;
      padding-right: 1.5rem !important;
      line-height: 3.4rem !important;
      padding-top: 1em !important;
    }
    .about-image{
      height: 24em !important;
    }
  }
  @media only screen and (max-width: 960px) {
    .about-text{
      font-size: 2.8rem !important;
      line-height: 2.9rem !important;
      padding-right: 1.5rem !important;
      padding-top: 0em !important;
    }
    .about-image-text{
      padding-left: 3rem !important;
    }
  }
  @media only screen and (max-width: 800px) {
    .about-text{
      padding-right: 1rem !important;
      font-size: 1.8rem !important;
      line-height: 2rem !important;
    }
    .about-image-text{
      padding-left: 1rem !important;
    }
    .about-image{
      height: 18em !important;
    }
  }
  @media only screen and (max-width: 550px) {
    .about-container{
      flex-wrap: wrap !important;
    }
    .about-image{
      width: 100% !important;
    }
    .about-image-text{
      width: 100% !important;
      padding-bottom: 1em !important;
      padding-left: 2em !important;
    }
  }
  .btn-contact-us{
    border-radius: 30px;
    color: #fff;
    background-color: #1f5191;
    padding: 10px 36px;
    font-size: 1.2rem;
    border: 1px solid #fff;
    cursor: pointer;
    margin-top: 1.8em;

  }
  .btn-contact-us:hover{
    background-color:white;
    color: #1b1a1a;
   
  }
  .about-image-text{
    width:50%;
    background-color: #1f5191;
    padding-left: 5em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   padding-top: 1em;
  }
  .news-date{
    margin-top: 1.5em;
    font-size: 1.2rem;
    color:#777777;
    margin-bottom: 0.6em;
  }

  .news-text{
    text-align: start;
    font-size: 1.7rem;
    font-weight: 100;
    padding-right: 1em;
  }
  
