.footer-title {
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid #1f5191;
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.title-wrapper{
    margin-bottom: 30px;
}
@media only screen and (max-width: 767px){
    footer{
        flex-wrap: wrap !important;
    }
    .footer-col{
        width: 100% !important;
        flex-basis: 100% !important;
        margin-top: 2em !important;
    }
    
}




.footer-link {
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #000;
    text-decoration: none;
    font-size: 16px;
    text-transform: capitalize;
}
.footer-social{
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
}